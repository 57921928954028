#root {
    height:100%;
}

:root {
    --primary-color: #1B75BC;
    --dark-primary-color: #0b6dc6;
    --secondary-color: #339eff;
    --color-gray: #707070;
    --color-border-input: #9b9b9b;
    --color-text: #212121;
    --color-black: black;
    --color-white: white;
    --warning: #ffc107;
    --danger: #dc3545;
    --success: #28a745;
    --size-arrow: 35px;
}

body, html {
    height:100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

html::-webkit-scrollbar {
    display: none;
}

button {
    cursor: pointer;
}

.slick-prev {
    left: 3% !important;
    z-index: 1;
}
.slick-next {
    right: 3% !important;
    z-index: 1;
}

.slick-prev, .slick-next {
    width: var(--size-arrow) !important;
    height: var(--size-arrow) !important;
}


.slick-prev::before, .slick-next::before {
    /*color: var(--primary-color) !important;*/
    font-size: var(--size-arrow) !important;
}


@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Ubuntu Mono'), local('UbuntuMono-Regular'), url('/fonts/Ubuntu_Mono/UbuntuMono-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'SFProText';
    src: local('SFProText'), local('SFProText-Regular'),
    url('/fonts/SFProText/SFProText-Regular.ttf') format('truetype'),
    url('/fonts/SFProText/SFProText-Regular.otf') format('opentype') ;
}


@font-face {
    font-family: 'SFProText-Bold';
    src: url('/fonts/SFProText/SFProText-Bold.ttf') format('truetype'),
    url('/fonts/SFProText/SFProText-Bold.otf') format('opentype') ;
}


@font-face {
    font-family: 'Brown-Bold';
    src: url('/fonts/Brown-Bold.otf') format('opentype') ;
}

.timeline .timeline-item .timeline-content {
    padding: 3em 0 0 2em !important;
}

.timeline .timeline-item .timeline-marker {
    top: 3.2rem !important;
}
